import styled from "styled-components"

export default styled.div`
     ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    padding-left: 5%; 
    text-indent: -.7em;
    margin: 1% 0% 1% 0%
  }
  
  li::before {
    content: "• ";
    color:   #ffb636;
    font-size: 30px;
  }
`;