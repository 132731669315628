import React from 'react'
import { graphql } from "gatsby"
import SEO from "../components/seo"
import styled from 'styled-components'
import Layout from "../components/layout"
import ImgHeader from "../components/ImgHeader"
import CardListing from "../components/cards/CardListing"


import CenterDiv from '../components/elements/CenterDiv'
import TitleDiv from "../components/elements/TitleDiv"
import ListDiv from "../components/elements/ListDiv"

import corporate from "../assets/corporates/corporate.png"
import engaged from "../assets/corporates/engaged.png"
import VHCenter from '../components/elements/VHCenter'
import Title from "../components/elements/Title"
import image from "../assets/corporates/Corporates.jpg"

const Corporates = ({ data }) => {
  const { title } = data.ImgHeaderQuery
  const background = data.ImgHeaderQuery.backgroundImg.fluid.src

  const firstTitle = data.TitleDivQuery.edges[0].node.header
  const secondTitle = data.TitleDivQuery.edges[1].node.header
  const thirdTitle = data.TitleDivQuery.edges[2].node.header
  const fourthTitle = data.TitleDivQuery.edges[3].node.header

  const whyHappinessContent = data.contentBlock.edges[0].node.body.childContentfulRichText.html
  const whyHappinessImg = data.contentBlock.edges[0].node.image.fluid.src
  const whyHappinessWidth = data.contentBlock.edges[0].node.width

  const researchBlock1 = data.contentBlock.edges[1].node.body.childContentfulRichText.html
  const researchBlock2 = data.contentBlock.edges[2].node.body.childContentfulRichText.html
  const researchBlock3 = data.contentBlock.edges[3].node.body.childContentfulRichText.html
  const researchBlock4 = data.contentBlock.edges[4].node.body.childContentfulRichText.html

  const typeOfEmployessBlock5 = data.contentBlock.edges[5].node.body.childContentfulRichText.html
  const list = data.contentBlock.edges[6].node.body.childContentfulRichText.html



  const Div = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 2%;
    padding-top: 2%;
}
`
  return (
    <Layout>
      <SEO title="Corporates" />
      <ImgHeader position="50% 40%" image={background} title={title} />
      <TitleDiv color="#feb600" style={{ marginTop: "0" }}>
        <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: firstTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      {/* <img src={background} className="w-100"></img> */}
      <CenterDiv className="container w-100">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8 mb-4">
            <img className="w-100" src={whyHappinessImg} />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 font-size text-justify pt-sm-3" >
            <div dangerouslySetInnerHTML={{ __html: whyHappinessContent }}></div>
          </div>
        </div>
      </CenterDiv>
      <TitleDiv color="#85b8cd ">
        <VHCenter><div><Title smFont="11.5px"><div dangerouslySetInnerHTML={{ __html: secondTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <CenterDiv className="container" >
        <h2 className="avenir-black"><div dangerouslySetInnerHTML={{ __html: thirdTitle }}></div></h2>
      </CenterDiv>
      <div className="container" style={{ marginTop: "5%", marginBottom: "1%" }}>
        <CardListing path="/corporates" btn={false} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 font-size avenir-heavy" >
            <div className="font-size avenir-heavy">
              <span dangerouslySetInnerHTML={{ __html: researchBlock1 }}></span>
              <span style={{ color: "#85b8cd", fontWeight: "bolder" }} dangerouslySetInnerHTML={{ __html: researchBlock2 }}></span>
              <span style={{ color: "#ffb636", fontWeight: "bolder" }} dangerouslySetInnerHTML={{ __html: researchBlock3 }}></span>
              <span style={{ color: "#ec8047", fontWeight: "bolder" }} dangerouslySetInnerHTML={{ __html: researchBlock4 }}></span>
            </div>
          </div>
        </div>
      </div>
      <div className="container font-size" style={{ marginTop: "3%" }} dangerouslySetInnerHTML={{ __html: typeOfEmployessBlock5 }}>
      </div>
      <div className="container font-size text-justify">
        <ul className="font-size text-justify list">
          {

            data.ListsQuery.edges.map((edge) => {
              return (
                <li path="/corporates" textLeft="left" > {edge.node.listText} </li>
              )
            })


          }
        </ul>
      </div>

      <TitleDiv color="#ed6c2b">
        <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: fourthTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <div className="container" style={{ marginTop: "5%", marginBottom: "1%" }}>
        <CardListing path="/corporates/ourService" />
      </div>
    </Layout>
  )
}

export default Corporates

export const query = graphql`
    query CorporatesPageQuery {
        ImgHeaderQuery: contentfulImgHeader(path: { eq: "/corporates" }) {
            title
            path
            backgroundImg {
              fluid (quality: 100){
                src
              }
            }
        }
        TitleDivQuery: allContentfulTitleDiv(
          filter: { path: { eq: "/corporates" }}, 
          sort: { fields: orderId }
          )
        {
          edges {
            node {
              orderId
              header 
            }
          }
        }

        ListsQuery: allContentfulLists(
          filter: { path: { eq: "/corporates" }}, 
          sort: { fields: orderId }
          )
        {
          edges {
            node {
              orderId
              listText 
            }
          }
        }
        contentBlock: allContentfulBlocks(
          filter: { path: { eq: "/corporates" } }
          sort: { fields: orderId }
        ) {
          edges {
            node {
              body {
                childContentfulRichText {
                  html
                }
              }
              image{
                  fluid{
                      src
                  }
              }
            }
          }
        }
    }
`
